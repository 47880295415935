import './LocalizationWrapper.scss';
import { User, UserQuery, useUserLazyQuery } from 'generated/graphql';
import { getAccessToken, logoutCleanup } from 'helpers/auth';
import { getLocalisationLanguage } from 'helpers/locales';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export const LANGUAGE_LOCAL_STORAGE_KEY = 'lng';

const LocalizationWrapper = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const { handleMsgType } = useNotification();

  const [isLoading, setIsLoading] = useState(true);

  const setLanguage = (user: User) => {
    try {
      if (user) {
        i18n.changeLanguage(getLocalisationLanguage(user.locale));
      } else {
        // Checks language from local storage when user reloads browser.
        // (*check src/libs/sz-client-shared-sub/components/organisms/LogoutButton/LogoutButton.tsx)
        const localStorageLang = localStorage.getItem(
          LANGUAGE_LOCAL_STORAGE_KEY
        );

        if (localStorageLang) {
          i18n.changeLanguage(localStorageLang);
          localStorage.removeItem(LANGUAGE_LOCAL_STORAGE_KEY);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [fetchUser] = useUserLazyQuery({
    onCompleted: (data: UserQuery) => {
      hubspotInitialize(data.User as User);
      setLanguage(data.User as User);
      Sentry.setUser({ subId: data.User.subId });
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
      logoutCleanup();
    },
  });

  useEffect(() => {
    if (getAccessToken()) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const hubspotInitialize = async (user: User) => {
    window.hsConversationsSettings = {
      identificationEmail: user.email || '',
      //TODO - add identification token when provided by BE
    };
    window.HubSpotConversations?.widget?.load();
  };

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};

export default LocalizationWrapper;
