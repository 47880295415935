import '@spotted-zebra-uk/ui-components/dist/sz-theme/_globalStyles.scss';
import { AuthenticationWrapper } from 'components/organisms/AuthenticationWrapper/AuthenticationWrapper';
import BaseCompanyThemeProvider from 'components/organisms/BaseCompanyThemeProvider/BaseCompanyThemeProvider';
import { FocusOnRouteChange } from 'components/organisms/FocusOnRouteChange/FocusOnRouteChange';
import LocalizationWrapper from 'components/organisms/LocalizationWrapper/LocalizationWrapper';
import UserWrapper from 'components/organisms/UserWrapper/UserWrapper';
import history from 'config/history';
import client from 'graphql/client';
import { localeToLanguageCode } from 'helpers/locales';
import { History } from 'history';
import AppSwitch from 'navigation/AppSwitch/AppSwitch';
import { memo, useEffect, useLayoutEffect, useState } from 'react';
import { LiveAnnouncer } from 'react-aria-live';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouterProps as NativeBrowserRouterProps,
  NavigationType,
  Router,
} from 'react-router-dom';
import ModalView from 'views/ModalView/ModalView';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@spotted-zebra-uk/ui';
import {
  useAccessibilityKeyboardDetection,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export interface BrowserRouterProps
  extends Omit<NativeBrowserRouterProps, 'window'> {
  history: History;
}

export const BrowserRouter = memo(
  ({ history, ...rest }: BrowserRouterProps) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location,
    });

    useLayoutEffect(() => {
      history?.listen(({ location, action }) => {
        setState({ location, action });
      });
    }, [history]);

    return (
      <Router
        {...rest}
        navigator={history}
        location={state.location}
        navigationType={state.action as NavigationType}
      />
    );
  }
);

function App() {
  const { i18n } = useTranslation();
  const { notificationContainer } = useNotification();
  const [language, setLanguage] = useState<string>('en');

  //Disable hubspot chat showing until user is initialized
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  useAccessibilityKeyboardDetection();

  const [isSafariFixIframeVisible, setSafariFixIframeVisibility] =
    useState(true);

  useEffect(() => {
    const timeoutRef = setTimeout(
      () => setSafariFixIframeVisibility(false),
      5000
    );

    return () => {
      clearTimeout(timeoutRef);
    };
  });

  //Catch language change which is done in LocalizationWrapper.
  i18n.on('languageChanged', (lng: string) => {
    setLanguage(localeToLanguageCode[lng]);
  });

  return (
    <LiveAnnouncer>
      {notificationContainer()}

      {isSafariFixIframeVisible && (
        <iframe
          src="https://tptests.com/api/candidate/safari"
          height="0"
          width="0"
          title="tp-safari-issue-fix"
          style={{ position: 'absolute' }}
        />
      )}
      <HelmetProvider>
        <Helmet>
          <html lang={language} />
          <title>Spotted Zebra Candidate</title>
        </Helmet>
        <ApolloProvider client={client}>
          <AuthenticationWrapper>
            <LocalizationWrapper>
              <UserWrapper>
                <ThemeProvider>
                  <BaseCompanyThemeProvider>
                    <BrowserRouter history={history}>
                      <FocusOnRouteChange>
                        <AppSwitch />
                      </FocusOnRouteChange>
                      <ModalView />
                    </BrowserRouter>
                  </BaseCompanyThemeProvider>
                </ThemeProvider>
              </UserWrapper>
            </LocalizationWrapper>
          </AuthenticationWrapper>
        </ApolloProvider>
      </HelmetProvider>
    </LiveAnnouncer>
  );
}

export default App;
